<template>
    <div class="w-100 date-breached-empty-screen">
        <v-app-bar app elevation="0" color="surface" class="justify-start py-3">
            <v-list-item class="d-flex justify-space-between webapp-main-topbar">
                <div class="flex-grow-1">
                    <div class="logo-container">
                        <v-img src="../assets/logo.svg"></v-img>
                    </div>
                </div>
            </v-list-item>
        </v-app-bar>
        
        <template v-if="isAuthenticated">
            <div class="d-flex flex-column align-center" style="height: calc(100vh - 128px);">
                <header>
                    <h6>VIBGYOR Organizational Leadership Training (VOLT)</h6>
                </header>
                <div>
                    <v-img src="../assets/vgos_internal.svg" alt="VGOS Internal User" width="550"></v-img>
                </div>
                <div class="text-container mt-4 text-center">
                    <p v-if="errorMessage" class="font-weight-bold text-center text-h6">{{ errorMessage }}</p>
                    <v-btn v-else color="elevation-0 btn primary-btn rounded-full" @click="startNow">Start Assessment</v-btn>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="!errorMessage">
                <v-dialog v-model="isLoading" hide-overlay persistent width="300">
                    <v-progress-linear indeterminate></v-progress-linear>
                </v-dialog>
            </template>
            <template v-else>
                <div class="d-flex flex-column align-center" style="height: calc(100vh - 128px);">
                    <header>
                        <h6>{{ errorMessage }}</h6>
                    </header>
                    <div>
                        <v-img src="../assets/user_not_found.svg" alt="User Not Found" width="550"></v-img>
                    </div>
                    <div class="text-container mt-4 text-center">
                        
                        <!-- <v-btn color="elevation-0 btn primary-btn rounded-full" @click="logoutTeacher">Back</v-btn> -->
                        <v-btn color="elevation-0 btn primary-btn rounded-full" @click="logoutTeacher">
                        <v-icon left>
                        mdi-keyboard-backspace
                        </v-icon>
                        Back
                        </v-btn>
                    </div>
                </div>  
            </template>
            
        </template>

        <!-- Add dynamic content if window.innerWidth <= 1024 -->
        <template v-if="screenWidth <= 1024 && isAuthenticated">
            <div class="w-100 date-breached-empty-screen">
                <v-app-bar app elevation="0" color="surface" class="justify-start py-3">
                    <v-list-item class="d-flex justify-space-between webapp-main-topbar">
                        <div class="flex-grow-1">
                            <div class="logo-container">
                                <v-img src="../assets/logo.svg"></v-img>
                            </div>
                        </div>
                        <v-list-item-action class="mx-0 my-0">
                            <div class="d-flex align-center user-profile-container">
                                <p role-type="subtitle-3" class="px-0 py-0 mb-0 webapp-text">Hello,</p>
                                <p role-type="subtitle-3" class="px-0 py-0 mb-0 ml-1 webapp-username" v-if="userInfo?.personalInfo != null">{{ userInfo?.personalInfo.firstName }} 👋</p>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark v-bind="attrs" v-on="on" text icon>
                                            <v-avatar>
                                                <v-img src="../assets/user.png"></v-img>
                                            </v-avatar>
                                        </v-btn>
                                    </template>
                                    <v-btn @click="logout">logout</v-btn>
                                </v-menu>
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </v-app-bar>

                <div class="d-flex justify-center flex-column align-center pa-4" style="height: calc(100vh - 80px);">
                    <div>
                        <img src="../assets/Submit.svg" alt="Date is breached" />
                    </div>
                    <div class="text-container mt-12 text-center">
                        <p>
                            Thank you for expressing your interest. We have successfully recorded your preferences. An email with a link to the assessment has been sent to you. Kindly complete the assessment on a laptop or desktop equipped with a webcam.
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import keycloakAuthMixin from '../mixins/keycloakAuthMixin';
import keycloakService from "../services/KeycloakService";
export default {
    name: 'InternalUserLanding',
    data() {
        return {
            screenWidth: window.innerWidth, // Store the initial screen width
        }
    },
    async created() {
        window.addEventListener('resize', this.updateScreenWidth); // Listen for window resizing
        await this.initializeAuth();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth); // Cleanup the event listener
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth; // Update the screen width when resized
        },
        logoutTeacher(){
            keycloakService.doLogout();
        },
        startNow() {
            this.$router.push("/assesment-instruction")
        },
        // async initializeKeycloak() {
        //     try {
        //         this.isLoading = true;
        //         keycloakService.initKeycloak(async () => {
        //             const urlParams = new URLSearchParams(window.location.search);
        //             const code = urlParams.get('code'); // Extract the 'code' parameter
        //             if(code) {
        //                 console.log("code=", code)                    
        //                 this.errorMessage = null;
        //                 this.isLoading = true;
        //                 const res = await this.handleSSOLogin(code);
        //                 if (res) {
        //                     this.errorMessage = null;
        //                     if (res.flag) {
        //                         this.isAuthenticated = true;
        //                         console.log("SSO authentication successful");
        //                     } else {
        //                         if (res.isSso) {
        //                             this.isAuthenticated = false
        //                             this.errorMessage = "User not found in the system";
        //                         } else {
        //                             if(res.error_description) {
        //                                 this.isAuthenticated = false
        //                                 this.errorMessage = res.error_description;
        //                             }
        //                         }
        //                     }
        //                 } else {
        //                     this.isAuthenticated = true;
        //                     console.log("No response from handleSSOLogin");
        //                 }
        //                 this.isLoading = false;
        //             }
        //         });


        //         // const urlParams = new URLSearchParams(window.location.search);
        //         // const code = urlParams.get('code'); // Extract the 'code' parameter
        //         // if(code) {
        //         //     console.log("code=", code)                    
        //         //     this.errorMessage = null;
        //         //     this.isLoading = true;
        //         //     const res = await this.handleSSOLogin(code);
        //         //     if (res) {
        //         //         this.errorMessage = null;
        //         //         if (res.flag) {
        //         //             this.isAuthenticated = true;
        //         //             console.log("SSO authentication successful");
        //         //         } else {
        //         //             if (res.isSso) {
        //         //                 this.isAuthenticated = false
        //         //                 this.errorMessage = "User not found in the system";
        //         //             } else {
        //         //                 if(res.error_description) {
        //         //                     this.isAuthenticated = false
        //         //                     this.errorMessage = res.error_description;
        //         //                 }
        //         //             }
        //         //         }
        //         //     } else {
        //         //         this.isAuthenticated = true;
        //         //         console.log("No response from handleSSOLogin");
        //         //     }
        //         //     this.isLoading = false;
        //         // } else {
        //         //     this.isLoading = true;
        //         //     keycloakService.initKeycloak(this.$onAuthenticatedCallback);
        //         // }                
        //     } catch (error) {
        //         console.error('Keycloak initialization failed', error);
        //         this.errorMessage = "Authentication failed. Please try again.";
        //         this.isAuthenticated = false;
        //         this.isLoading = false;
        //     }
        // }
    },
    computed: {
        isLoading() {
            return this.isLoading;
        },
        isAuthenticated() {
            return this.isAuthenticated;
        },
        errorMessage() {
            return this.errorMessage;
        }
    },
    mixins: [keycloakAuthMixin]
}
</script>
<style></style>