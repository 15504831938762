import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                black: "#212121",
                blue: "#3F41D1",
                "blue-04": "#CFD5F1",
                "blue-05": "#E1E8F5",
                success: "#06C270",
                "success-01": "#E1F8EE",
                primary: '#005b64',
                secondary: '#82d2da',
                "light-red": "#E697AB",
                "brown": "#310000",
                secondaryAccent:'#caecef',
                surface: '#f7f7f7',
                sufaceAccent: '#fbf5f2',
                accent: '#E697AB',
                error: '#E6393E',
                textcolor: '#005B64',
                answered:'#009051',
                skipped:'#F6E866',
                bookmarked:'#E868E3',
                textAccent: '#FD786E',
                textAccentBackground: '#FFEED9'




//                 const primaryColor = Color(0xFF82D2DA);
// const primaryLightShade = Color(0xFFC4EAEE);
// const primaryDarkShade = Color(0xFF4ebfcb);
// const accentColor = Color(0xFF6C367E);
// const primarySurfaceColor = Color(0xFFFAF8ED);
// const accentSurfaceColor = Color(0xFFFBF5F2);
// const primaryOnColor = Color(0xFF005B64);
// const accentOnColor = Color(0xFFFBF5F2);
// const successColor = Color(0xFF228B22);
// const errorColor = Color(0xFFD70015);
// const warningColor = Color(0xFFFF9500);
            },
         
        },
    },
});