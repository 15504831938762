import Vue from "vue";
import VueRouter from "vue-router";
import AuthService from "@/services/AuthService";
import InternalUserLanding from "../views/InternalUserLanding.vue";

const AuthView = () => import("../views/AuthView");
const HomeView = () => import("../views/HomeView");
const RegistrationView = () => import("../views/RegistrationView");
const AssessmentView = () => import("../views/AssessmentView");
const SucessSubmitView = () => import("../views/SucessSubmitView");
const FailedView = () => import("../views/FailedView");
const InterestsView = () => import("../views/InterestsView.vue");
const ReportView = () => import("../views/ReportView");
const GraphReportView = () => import("../views/GraphReportView");
const AdaptiveAssessmentView = () => import("../views/AdaptiveAssessmentView");
const DynamicAssessmentView = () => import("../views/DynamicAssessmentView");
const MainsScheduling = () => import("../views/MainsScheduling");
const FaceRegister = () => import("../views/FaceRegister");
const DemoNotice = () => import("../views/DemoNotice.vue");
const RecordDemo = () => import("../views/RecordDemo.vue");
const UploadDemo = () => import("../views/UploadDemo.vue");
const DemoSuccess = () => import("../views/DemoSuccess.vue");
const CampaignInviteVue = () => import("../views/CampaignInviteVue");
const TermsAndConditions = () => import("../views/TermsAndConditions");
const PsychometricAssessmentView = () => import('../views/PsychometricAssessmentView');
const DemoInstruction = () => import("../views/DemoInstruction.vue");
const DateBreached = () => import("../views/DateBreached.vue");
const Faq = () => import('../views/faq.vue');
const AssessmentInstructionView = () => import('../views/AssessmentInstructionView.vue');
const AppRedirectionView = () => import('../views/AppRedirectionView.vue');
const StaticAssessment = () => import('../views/staticAssessmentLandingPage.vue');
const StaticAssessmentHome = () => import('../views/staticAssessmentHome.vue');
const GenericAssessmentView = () => import('../views/GenericAssessmentView.vue');
const StaticAssessmentInstructions = () => import('../views/staticAssessmentInstructions.vue');
const StaticAssessmnetCampaignDetails = () => import('../views/staticAssessmnetCampaignDetails.vue');
const StaticAssessmentSuccess = () => import('../views/staticAssessmentSuccess.vue');
const StaticAssessmentFailedView = () => import("../views/staticAssessmentFailedView.vue");
import keycloakService from "../services/KeycloakService";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "AuthView",
    component: AuthView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/staticassessment",
    name: "StaticAssessment",
    component: StaticAssessment,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/ps/staticassessment",
    name: "PS-StaticAssessment",
    component: StaticAssessment,
    props: { source: 'PS' },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/staticassessmentinstructions/:userType/:campaignId/:assessmentId/:assessmentResultId/:grade/:language",
    name: "StaticAssessmentInstructions",
    component: StaticAssessmentInstructions,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/staticAssessmnetcampaigndetails/:userType/:campaignId/:assessmentId/:assessmentResultId/:grade/:language",
    name: "StaticAssessmnetCampaignDetails",
    component: StaticAssessmnetCampaignDetails,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/staticassessmenthome/:userType/:campaignId/:assessmentId/:assessmentResultId/:grade/:language",
    name: "GenericAssessmentView",
    component: GenericAssessmentView,
    props: (route) => ({
      ...route.params,       // Spread the route params, if any
      audienceType: 'MESTA',  // Default value for audienceType
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/staticassessmentsuccess/:option",
    name: "StaticAssessmentSuccess",
    component: StaticAssessmentSuccess,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/staticassessmentfailed",
    name: "StaticAssessmentFailedView",
    component: StaticAssessmentFailedView,
  },
  {
    path: "/interests",
    name: "InterestsView",
    component: InterestsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mains-scheduling",
    name: "MainsScheduling",
    component: MainsScheduling,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/tnc",
    name: "TermsAndConditions",
    component: TermsAndConditions,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/failed",
    name: "FailedView",
    component: FailedView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "RegistrationView",
    component: RegistrationView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaignInvite",
    name: "CampaignInviteVue",
    component: CampaignInviteVue,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/report",
    name: "ReportView",
    component: ReportView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reportStatus",
    name: "GraphReportView",
    component: GraphReportView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/success",
    name: "SucessSubmitView",
    component: SucessSubmitView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assesment-instruction",
    name: "AssessmentInstructionView",
    component: AssessmentInstructionView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assessment",
    name: "AssessmentView",
    component: AssessmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/adaptive-assessment",
    name: "AdaptiveAssessmentView",
    component: AdaptiveAssessmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dynamic-assessment",
    name: "dynamicAssessmentView",
    component: DynamicAssessmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/proctoring-registration",
    name: "proctoringRegistration",
    component: FaceRegister,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demonotice",
    name: "demonotice",
    component: DemoNotice,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demo-video-instruction",
    name: "demoVideoInstruction",
    component: DemoInstruction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/recorddemo",
    name: "recorddemo",
    component: RecordDemo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/uploaddemo",
    name: "uploaddemo",
    component: UploadDemo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demosuccess",
    name: "demosuccess",
    component: DemoSuccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/date-breached",
    name: "dateBreached",
    component: DateBreached,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/test",
    name: "PsychometricAssessmentView",
    component: PsychometricAssessmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "testFaqs",
    component: Faq,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/app-redirection",
    name: "AppRedirectionView",
    component: AppRedirectionView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vgos-internal",
    name: "InternalUserLanding",
    component: InternalUserLanding,
    meta: {
      requiresAuth: false,
    }
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "Hubble STAR"
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!AuthService.isAuthenticated()) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;